import * as R from 'ramda'
import React from 'react'
import Img from 'gatsby-image'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { mapIndexed, notNilOrEmpty } from '../lib/Helpers'

export default props => {
  return (
    <div className="container">
      {props.filters && (
        <section className="filters">
          <FormControl variant="outlined" className="filter-dropdown">
            <InputLabel id="sort-label">Sort</InputLabel>
            <Select
              label="Sort"
              labelId="sort-label"
              id="sort-select"
              value={props.defaultSort}
              onChange={e => props.handleCategorySort(e.target.value)}
            >
              <MenuItem value="asc">A - Z</MenuItem>
              <MenuItem value="desc">Z - A</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" className="filter-dropdown">
            <InputLabel id="cats-label">Vendor Type</InputLabel>
            <Select
              label="Vendor Type"
              labelId="cats-label"
              id="cats-select"
              value={props.selectedVendorType}
              onChange={e => props.handleVendorTypeChange(e.target.value)}
            >
              <MenuItem value=""> - ALL - </MenuItem>
              {mapIndexed(({ node: cat }, index) => {
                return (
                  <MenuItem key={index} value={cat.title}>
                    {R.toUpper(cat.title)}
                  </MenuItem>
                )
              })(
                R.compose(R.sort(R.ascend(R.path(['node', 'title']))))(
                  props.vendorType
                )
              )}
            </Select>
          </FormControl>
          <FormControl variant="outlined" className="filter-dropdown">
            <InputLabel id="cats-label">Category</InputLabel>
            <Select
              label="Vendor Type"
              labelId="cats-label"
              id="cats-select"
              value={props.selectedCategory}
              onChange={e => props.handleCategoryChange(e.target.value)}
            >
              <MenuItem value="">
                {' '}
                -{' '}
                {notNilOrEmpty(props.selectedVendorType)
                  ? 'ALL'
                  : 'Pick a Vendor Type First'}{' '}
                -{' '}
              </MenuItem>
              {mapIndexed(({ node: vendor }, index) => {
                return (
                  R.equals(props.selectedVendorType, vendor.title) &&
                  mapIndexed((cat, i) => {
                    return (
                      <MenuItem key={i} value={cat.title}>
                        {R.toUpper(cat.title)}
                      </MenuItem>
                    )
                  })(vendor.categories)
                )
              })(
                R.compose(R.sort(R.ascend(R.path(['node', 'title']))))(
                  props.vendorType
                )
              )}
            </Select>
          </FormControl>
          <Autocomplete
            autofill="true"
            options={R.compose(R.sort(R.ascend(R.path(['node', 'title']))))(
              props.defaultOrder
            )}
            getOptionLabel={({ node: option }) =>
              option.title || option.company_primary_contact
            }
            className="search-box filter-dropdown"
            onChange={props.onAutocompleteChange}
            classes={{
              popper: 'popper-autocomplete'
            }}
            renderInput={params => (
              <TextField
                {...params}
                className="search-box-label"
                label="Company"
                variant="outlined"
                color="secondary"
              />
            )}
          />
        </section>
      )}
      <section className="challenges">
        {mapIndexed(({ node: challenge }, index) => {
          return (
            <div key={index} className={`challenges__single animated`}>
              <div className="challenge_image">
                {notNilOrEmpty(challenge.challenge_image) && (
                  <Img
                    fluid={challenge.challenge_image.asset.fluid}
                    objectFit="scale-down"
                    objectPosition="50% 50%"
                  />
                )}
              </div>
              <p className="challenge_title">{challenge.title}</p>
              <small className="challenge_description">
                {challenge.challenge_description}
              </small>
              <AniLink
                cover
                direction="left"
                className="btn btn--yellow btn--small text--center"
                duration={1}
                bg={`
                  url(${challenge.challenge_hero_overlay_image.asset.fluid.src})
                  center / 50%     /* position / size */
                  no-repeat        /* repeat */
                  fixed            /* attachment */
                  padding-box      /* origin */
                  content-box      /* clip */
                  #00021a          /* color */
                `}
                to={`/challenge/${challenge.slug.current}`}
              >
                Visit Challenge Booths
              </AniLink>
            </div>
          )
        })(
          R.compose(R.sort(R.ascend(R.path(['node', 'title']))))(
            props.challengeType
          )
        )}
      </section>
    </div>
  )
}
